import { configureStore } from "@reduxjs/toolkit";
import { countDownReducer, userFormReducer } from "./features";

export const store = configureStore({
  reducer: {
    countDown: countDownReducer,
    userForm: userFormReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
