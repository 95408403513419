import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../../interface";
const initialState: User = {
  name: "",
  email: "",
  nameActive: false,
  emailActive: false,
  emailError: { status: false, message: "" },
  nameError: { status: false, message: "" },
};

const userFormSlice = createSlice({
  name: "userForm",
  initialState,
  reducers: {
    setNameInputActive: (state) => {
      state.nameActive = true;
    },
    setNameInputInActive: (state) => {
      state.nameActive = false;
    },
    setEmailInputActive: (state) => {
      state.emailActive = true;
    },
    setEmailInputInActive: (state) => {
      state.emailActive = false;
    },
    setName: (state, { payload }) => {
      state.name = payload;
      if (!payload) {
        state.nameError = { status: true, message: "Name is required" };
      }
      state.nameError = { status: false, message: "" };
    },
    setEmail: (state, { payload }) => {
      state.email = payload;
      if (!payload) {
        state.emailError = { status: true, message: "Email is required" };
      }
      if (!/\S+@\S+\.\S+/.test(payload)) {
        state.emailError = { status: true, message: "Enter a valid email" };
      } else {
        state.emailError = { status: false, message: "" };
      }
    },
    setNameError: (state) => {
      state.nameError = { status: true, message: "Name is required" };
    },
    setEmailError: (state, { payload }) => {
      if (!payload) {
        state.emailError = { status: true, message: "Email is required" };
      } else if (!/\S+@\S+\.\S+/.test(payload)) {
        state.emailError = { status: true, message: "Enter a valid email" };
      }
    },
  },
});
export const {
  setEmail,
  setEmailInputActive,
  setEmailInputInActive,
  setName,
  setNameInputActive,
  setNameInputInActive,
  setNameError,
  setEmailError,
} = userFormSlice.actions;
export const userFormReducer = userFormSlice.reducer;
