import { lazy } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Admin from "./routes/Admin";

//const Admin = lazy(() => import("./routes/Admin"));
const User = lazy(() => import("./routes/User"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<User />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
